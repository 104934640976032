import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppConfig, ConfigService } from "../core/config.service";

declare global {
    interface Window {
        kindlyOptions?: {
            lang: string;
        };
    }
}

type KindlyLocale = 'sv' | 'no' | 'da' | 'fi';

@Component({
    selector: 'kindly-chat-bot',
    template: '',
})
export class KindlyChatBot implements OnInit {

    private scriptId = 'kindly-chat';

    constructor(
        private configService: ConfigService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        const config = this.configService.snapshot.config;
        this.createKindlyBot(config);
    }

    private createKindlyBot(config: AppConfig) {
        const market = config.currentMarket;
        const kindlyLang = this.getKindlyLocaleFrom(market);
        window.kindlyOptions = {
            lang: 'sv', //hardcoded for now since we only have swedish bot
        };

        const script = this.renderer.createElement('script');
        script.id = this.scriptId;
        script.src = config.user && kindlyLang === 'sv' ? 'https://chat.kindlycdn.com/kindly-chat.js' : '';
        script.setAttribute('data-bot-key', '3780f91b-19a9-464d-a2e4-986e5de61b69');
        script.setAttribute('data-shadow-dom', 'true');
        script.defer = true;

        // Append script to body
        this.renderer.appendChild(this.document.body, script);
    }

    public getKindlyLocaleFrom(market: string): KindlyLocale {
        switch (market) {
            case 'se':
            case 'sefi':
                return 'sv';
            case 'no':
                return 'no';
            case 'da':
            case 'dk':
                return 'da';
            case 'fi':
                return 'fi';
            default:
                return 'sv';
        }
    }
}
