import { Component, Input, OnInit } from '@angular/core';
import { MyCustomerBase, MyCustomerLanguageExtras } from '../../core/my-customers.service';

type ProductPcpTableMyCustomer = MyCustomerBase & MyCustomerLanguageExtras & {
    // These are missing (undefined) when customer exists.
    is_disabled?: boolean;
    missing?: boolean;
};

@Component({
    selector: 'nc-pcp-recipients-table',
    templateUrl: './pcp-recipients-table.component.html',
    styleUrls: ['./pcp-recipients-table.component.scss']
})
export class PcpRecipientsTableComponent implements OnInit {

    @Input() header: string;
    @Input() customers: ProductPcpTableMyCustomer[];

    pending = true;
    page = 0;
    pageLength = 40; // Number of items to display
    start = this.page * this.pageLength; // Start index
    end = this.start + this.pageLength; // End index
    total = 0; // Total item count
    pageCount = 0; // Number of pages calculated from total items
    pageCustomers?: ProductPcpTableMyCustomer[] = null;
    totalCustomers?: ProductPcpTableMyCustomer[] = [];

    ngOnInit() {
        if (this.customers) {
            this.setCustomers(this.customers);
            this.pending = false;
        }
    }

    protected setCustomers(customers: ProductPcpTableMyCustomer[]): void {
        this.totalCustomers = customers;
        this.total = customers.length;
        this.pageCount = Math.ceil(this.total / this.pageLength - 1);
        this.initPage();
        this.update();
    }

    update() {
        if (this.page === 0) {
            this.start = 0;
        } else {
            this.start = this.page * this.pageLength;
        }

        if (this.page === this.pageCount) {
            this.end = this.total;
        } else {
            this.end = this.start + this.pageLength;
        }
    }

    nextPage($event) {
        $event.preventDefault();
        this.page += 1;
        this.update();
        this.initPage();
    }

    previousPage($event) {
        $event.preventDefault();
        this.page -= 1;
        this.update();
        this.initPage();
    }

    getSelectedCustomers(): ProductPcpTableMyCustomer[] {
        return Array.from(this.totalCustomers);
    }

    initPage() {
        this.pageCustomers = this.totalCustomers.slice(this.start, this.end);
    }

    languageFromCode(language: string) {
        if (!language) {
            return '';
        }

        return language.split('_')[0];
    }

}
