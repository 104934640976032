import { HttpParams } from '@angular/common/http';
import { Product, ProductTypes, ProductVariation } from 'src/app/core/product.service';
import { ShoppingCartItem } from 'src/app/core/shopping-cart-data.service';
import { ConfigService } from '../core/config.service';

export function productImage(product: Product): string {
    if (product.images.length) {
        return product.images[0].url;
    }

    if (product.type === 'wrapper' && product.children.length) {
        if (
            product.children[0].choices.length &&
            product.children[0].choices[0].product.images.length
        ) {
            return product.children[0].choices[0].product.images[0].url;
        }
    }

    return '/assets/images/placeholder.jpg';
}

export function cartItemImage(item: ShoppingCartItem): string {
    if (item.images.length) {
        return item.images[0].url;
    }

    return '/assets/images/placeholder.jpg';
}

export function getMaxQuantity(product: Product) {
    return Math.min(
        product.maxItemsOnOrderCalculated ?? 1000000,
        product.maxItemsTotalCalculated ?? 1000000,
    );
}

export function productFindVariationByVariantId(
    product: Product,
    variantId?: number,
): ProductVariation | undefined {
    if (product.type === ProductTypes.WRAPPER && variantId) {
        for (const child of product.children) {
            for (const choice of child.choices) {
                if (choice.product?.id == variantId) {
                    return choice;
                }
            }
        }
    }

    return undefined;
}

// Product that is unavailable for users, but is viewed by an admin.
export function isUnavailableForRegularUsers(product: Product): boolean {
    const adminFieldsAvailable = product?.adminFields?.available;

    if (!adminFieldsAvailable) {
        return false;
    }

    return (
        !adminFieldsAvailable.publish ||
        !adminFieldsAvailable.areaNumbers ||
        !adminFieldsAvailable.activeFrom ||
        !adminFieldsAvailable.activeTo ||
        !adminFieldsAvailable.titleId
    );
}

export function productBuildUrl(
    configService: ConfigService,
    product: Product,
    variation?: ProductVariation,
) {
    if (!product || product.bonusCampaignId) {
        return ''; // Bonus products should not link to the product page.
    }

    const path = configService.getCategoryPath(product.categories[0]);
    let url = `/product/${path}/${product.id}`;

    // Add Variant ID as a query parameter.
    if (variation?.product?.id) {
        const params = new HttpParams().set('variantId', variation.product.id.toString());
        url = url.concat(`?${params.toString()}`);
    }

    return url;
}

export function shouldDisplayCampaignAddButton(
    product: Pick<Product, 'bonusCampaignId' | 'bonusWithLevelsCampaignId'>,
): boolean {
    return Boolean(product.bonusCampaignId || product.bonusWithLevelsCampaignId);
}
